import React from 'react'
import { Box, Text, Heading } from 'theme-ui'

export default function SubSectionTitle({ children }) {
  return <Box sx={styles.container}>{children}</Box>
}

const styles = {
  container: {
    width: 'fit-content',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '10px',
    color: 'text',
    margin: '0px 5px 8px',
    boxSizing: 'border-box',
  },
}

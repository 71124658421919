import React, { useContext } from 'react'
import { store } from './Context/Store'
import { Box, Button } from 'theme-ui'
export default function Footer({ setStyleSettings }) {
  const { globalState, dispatch } = useContext(store)
  return (
    <Box sx={styles.container}>
      <Button
        onClick={() =>
          dispatch({
            type: 'RESET_SETTINGS',
          })
        }
      >
        Reset Settings
      </Button>
    </Box>
  )
}

const styles = {
  container: {
    position: 'absolute',
    bottom: '0px',
    left: '0px',
    width: '100%',
    backgroundColor: 'primary',
    padding: '0.25rem',
    boxSizing: 'border-box',
    textAlign: 'right',
    button: {
      letterSpacing: '0px',
      borderRadius: '50px',
      backgroundColor: 'dark',
      color: 'light',
      border: 'none',
      padding: '0.5rem 0.75rem',
      fontSize: '12px',
      transition: 'all ease-in-out 0.5s',
      ':hover': { background: 'lightgrey', color: 'purple' },
    },
  },
}

import React, { useContext } from 'react'
import { Button, Box } from 'theme-ui'
import AccessibilityIcon from './assets/AccessibilityIcon.js'
import CloseIcon from './assets/CloseIcon'
import Header from './Header'
import StyleSettings from './StyleSettings.js'
import Footer from './Footer.js'
import './Widget.css'
import { store } from './Context/Store'

export default function Widget() {
  const { globalState, dispatch } = useContext(store)

  return (
    <Box sx={styles.container}>
      <Box sx={styles.innerContainer}>
        <Button
          sx={styles.button}
          onClick={() => dispatch({ type: 'OPEN_WIDGET' })}
        >
          <AccessibilityIcon />
        </Button>
        {globalState.widgetOpen && (
          <Box sx={styles.widgetContainer}>
            <CloseIcon
              className='closeButton'
              onClick={() => dispatch({ type: 'CLOSE_WIDGET' })}
            />
            <Box sx={styles.widgetBox}>
              <Header />
              <StyleSettings />
              <Footer />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    fontFamily: 'Roboto, sans-serif',
  },
  innerContainer: {
    fontFamily: 'Roboto, sans-serif',
  },
  button: {
    position: 'fixed',
    bottom: ['90px', '', '80px'],
    left: '0px',
    backgroundColor: 'primary',
    color: 'white',
    padding: '5px',
    border: 'solid 2px white',
    zIndex: 9998,
    img: { maxWidth: '50px' },
    svg: { path: { fill: 'white' } },
  },

  widgetContainer: {
    position: 'fixed',
    bottom: '10px',
    left: '10px',
    zIndex: '9999',
    maxWidth: '95%',
    '.closeButton': {
      position: 'absolute',
      top: '0rem',
      right: '0rem',
      backgroundColor: 'white',
      color: 'purple',
      borderRadius: '100px',
      height: '30px',
      width: '30px',
      transform: 'translate(50%, -50%)',
    },
  },
  widgetBox: {
    backgroundColor: 'background',
    minHeight: '300px',
    minWidth: '280px',
    height: '400px',
    maxHeight: '400px',
    maxWidth: '380px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    borderRadius: '10px',
    color: 'text',
    '::-webkit-scrollbar': { width: '10px' },
    '::-webkit-scrollbar-track': {
      background: '#2b2a2a',
      borderRadius: '0px 10px 10px 0px',
    },
    '::-webkit-scrollbar-thumb': { background: '#888' },
    '::-webkit-scrollbar-thumb:hover': { background: '#555' },
  },
}

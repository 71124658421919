import React from 'react'
import SubSectionTitle from '../TextContent/SubSectionTitle'
import { Box } from 'theme-ui'

export default function SettingsBox({ title, children, ...rest }) {
  return (
    <Box {...rest} sx={styles.container}>
      <SubSectionTitle>{title}</SubSectionTitle>
      {children}
    </Box>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '16px',
    backgroundColor: 'background',
    filter: 'brightness(0.9)',
    padding: '10px',
    borderRadius: '10px',
    margin: '0rem 0.5rem 0.5rem 0rem',
    flexGrow: 1,
  },
}

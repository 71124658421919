import React from 'react'
import { Box } from 'theme-ui'

export default function Header() {
  return (
    <Box sx={styles.container}>
      <h3>Accessibility Settings</h3>
    </Box>
  )
}

const styles = {
  container: {
    backgroundColor: 'primary',
    h3: {
      color: 'white',
      padding: '20px 30px',
      margin: '0rem 0rem 0.25rem',
      textAlign: 'center',
    },
  },
}

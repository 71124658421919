// ?===============================
// ?=====  3rd party Imports  =====
// ?===============================

import React from 'react'
import { Box, Image, Spinner } from '@theme-ui/components'
import { useStaticQuery } from 'gatsby'

// ?===================================
// ?==========  Component  ============
// ?===================================

export default function OpeningPage(props) {
  // ?==========================
  // ?====== Destructing  ======
  // ?==========================
  const {
    // businessData,
    // configData,
    // options,
    // gonationID,
    variantName,
  } = props

  const data = useStaticQuery(graphql`
    query MyQuery {
      businessData {
        name

        avatar {
          imageBaseUrl
          imagePrefix
          isDefault
        }
      }
    }
  `)

  // ?==============================
  // ?=====  Variable Creation  ====
  // ?==============================

  const {
    name,
    avatar: { imageBaseUrl, imagePrefix },
  } = data.businessData

  const variant = variantName ? variantName : 'openingPageV1'

  // ?=========================
  // ?===== JSX rendering =====
  // ?=========================
  return (
    <Box
      variant={`${variant}.container`}
      className='container openingPageV1'
      sx={{ variant: 'customVariants.openingPage' }}
    >
      <Box variant={`${variant}.content`} className='content'>
        <Image
          variant={`${variant}.logo`}
          className='logo'
          src={imageBaseUrl + '/' + imagePrefix}
          alt={name}
        />
        <Spinner sx={{ marginTop: '1rem' }} />
      </Box>
    </Box>
  )
}
